import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const RemoveFund = ({ fundId, myFundList, idx, updateMyFundList }) => {
  const [open, setOpen] = useState(false);

  const fundName = myFundList[fundId].name;
  const assetName = myFundList[fundId].assets[idx]?.name || null;

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const onRemove = () => {
    updateMyFundList(idx);
  };

  return (
    <>
      <IconButton
        variant='outlined'
        sx={{ padding: '0', minWidth: 0, width: '20px', height: '20px' }}
        onClick={handleOpenDialog}
      >
        <DeleteIcon fontSize='small' />
      </IconButton>

      <Dialog
        maxWidth={'xs'}
        PaperProps={{ sx: { width: '400px' } }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
          }
        }}
        open={open}
        onClose={handleCloseDialog}
        closeAfterTransition={false}
      >
        <DialogTitle textAlign={'center'}>Remove Asset</DialogTitle>
        <DialogContent
          dividers
          sx={{
            padding: '1rem auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <DialogContentText fontSize={14}>
            Are you sure to remove <span style={{ fontWeight: '700', color: '#90caf9' }}>{assetName}</span> from{' '}
            <span style={{ fontWeight: '700', color: '#90caf9' }}>{fundName}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => {
              onRemove();
              handleCloseDialog();
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveFund;
