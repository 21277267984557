import { Typography } from '@mui/material';
import { isDomestic, isMarketOpen } from '../../lib/utils';
import { useDate } from '../../hooks/useDate';

const MarketStatus = ({ country }) => {
  const today = useDate(country);

  return (
    <>
      {isDomestic(country) ? (
        <>
          {isMarketOpen(country) ? (
            <Typography variant='body2' component='p' color='primary'>
              Market opened
            </Typography>
          ) : (
            <Typography variant='body2' component='p' color='secondary'>
              Market closed
            </Typography>
          )}
        </>
      ) : (
        <>
          {isMarketOpen(country) ? (
            <Typography variant='body2' component='p' color='primary'>
              Market opened
            </Typography>
          ) : (
            <Typography variant='body2' component='p' color='secondary'>
              Market closed
            </Typography>
          )}
        </>
      )}
      <Typography variant='body2' component='p' color='grey'>
        {today.format('LL HH:mm:ss z')}
      </Typography>
    </>
  );
};

export default MarketStatus;
