import axios from 'axios';
import { sleep, isDomestic } from '../../lib/utils';

const getPortfolio = async (token, params) => {
  try {
    const API_URL = `/api/${params.tradingMode.toLowerCase()}/assets`;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        'cache-control': 'no-store',
        pragma: 'no-store',
        expires: '0'
      },
      params: params
    };

    await sleep(params.tradingMode === 'SANDBOX' ? 500 : 100);

    const response = await axios.get(API_URL, config);

    const result = isDomestic(params.country)
      ? {
          domestic: true,
          portfolio: response.data.output1.sort((a, b) => (a.prdt_name > b.prdt_name ? 1 : -1)),
          accountBalance: response.data.output2[0].prvs_rcdl_excc_amt,
          nass_amt: response.data.output2[0].nass_amt
        }
      : {
          domestic: false,
          portfolioUS: response.data.output1.sort((a, b) => (a.ovrs_pdno > b.ovrs_pdno ? 1 : -1)),
          profitLossUS: response.data.output2.ovrs_tot_pfls
        };

    return result;
  } catch (error) {
    console.log(error.response.data);
    const message = error.response.data.msg_cd === 'EGW00201' ? error.response.data.msg1 : error.message;
    throw new Error(message);
  }
};

const searchTickers = async (token, params) => {
  try {
    const API_URL = `/api/${params.tradingMode.toLowerCase()}/assets/search/`;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        'cache-control': 'no-store',
        pragma: 'no-store',
        expires: '0'
      }
    };

    const response = await axios.get(API_URL + params.ticker.toLowerCase(), config);

    console.log(response.data);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const portfolioService = {
  getPortfolio,
  searchTickers
};

export default portfolioService;
