import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPreferences } from '../../features/auth/authSlice';
import { toast } from 'react-toastify';
import { tooltipStyle } from '../../lib/constants';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  Stack,
  DialogContent,
  Tooltip,
  FormGroup,
  FormControl,
  FormControlLabel
} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import { useForm, Controller } from 'react-hook-form';

const AssetPreference = ({ assetPreferences, ticker, asset }) => {
  const dispatch = useDispatch();
  const { isSuccess, isError, message, preferences } = useSelector(state => state.auth);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = (updatedPreferences, ticker) => {
    console.log(updatedPreferences, ticker);
    const data = {};
    const newPreferences = {};

    updatedPreferences.forEach(el => {
      data[el] = true;
    });

    Object.assign(newPreferences, preferences);
    newPreferences.assets = { ...newPreferences.assets, [ticker]: data };

    dispatch(setPreferences(newPreferences));

    if (isSuccess) {
      toast.success('Individual asset preferences saved!');
    }

    if (isError) {
      toast.error(message);
    }
  };

  let updatedPreferences = [];
  const setPrefs = newPrefs => (updatedPreferences = [...newPrefs]);

  const [checkedState, setCheckedState] = useState([...assetPreferences]);

  const handleChange = name => {
    const updatedCheckedState = [...checkedState];

    updatedCheckedState.includes(name)
      ? updatedCheckedState.splice(updatedCheckedState.indexOf(name), 1)
      : updatedCheckedState.push(name);

    setCheckedState(updatedCheckedState);
  };

  setPrefs(checkedState);

  // const {
  //   handleSubmit,
  //   control,
  //   register,
  //   formState: { errors, isDirty, isValid }
  // } = useForm({
  //   defaultValues: {
  //     noStockWatering: assetPreferences.includes('noStockWatering'),
  //     buyOneTime: assetPreferences.includes('buyOneTime'),
  //     neverSell: assetPreferences.includes('neverSell'),
  //     sellOneTime: assetPreferences.includes('sellOneTime')
  //   }
  // });

  return (
    <>
      <SettingsOutlinedIcon sx={{ cursor: 'pointer' }} fontSize='small' onClick={handleClickOpen} />

      {open && (
        <Dialog
          maxWidth={'xs'}
          PaperProps={{ sx: { width: '400px' } }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
              }
            }
          }}
          open={open}
          onClose={handleClose}
          closeAfterTransition={false}
        >
          <DialogTitle textAlign={'center'}>Individual Stock Preferences</DialogTitle>
          {/* <AssetSetting assetPreferences={assetPreferences} setPrefs={setPrefs} /> */}
          <DialogContent
            dividers
            sx={{
              padding: '1rem auto',
              display: 'grid',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden'
            }}
          >
            <Stack direction={'row'} justifyContent='center' alignItems='center' gap={2} mt={1} mb={2}>
              <Box
                component={'fieldset'}
                border='1px solid rgba(255, 255, 255, 0.12)'
                borderRadius='6px'
                fontSize={'14px'}
                padding={'1rem 0 1rem 1.5rem'}
                width={'180px'}
              >
                <legend style={{ textAlign: 'left', marginLeft: '0.5rem' }}>Buy</legend>
                <FormControl fullWidth>
                  {/* <FormControl> */}
                  <FormGroup
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Stop buying more shares'
                      PopperProps={{ sx: tooltipStyle }}
                    >
                      <FormControlLabel
                        label='No Stock Watering'
                        value='noStockWatering'
                        id='noStockWatering'
                        control={
                          <input
                            type='checkbox'
                            name='noStockWatering'
                            checked={checkedState.includes('noStockWatering')}
                            onChange={e => handleChange('noStockWatering')}
                          />
                        }
                        sx={{ '& .MuiFormControlLabel-label': { paddingLeft: '0.5rem' } }}
                      />
                    </Tooltip>
                  </FormGroup>
                </FormControl>
              </Box>

              <Box
                component={'fieldset'}
                justifyContent={'space-between'}
                border='1px solid rgba(255, 255, 255, 0.12)'
                borderRadius='6px'
                fontSize={'14px'}
                padding={'1rem 0 1rem 1.5rem'}
                width={'180px'}
              >
                <legend style={{ textAlign: 'left', marginLeft: '0.5rem' }}>Sell</legend>
                <FormControl fullWidth>
                  <FormGroup
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Tooltip
                      disableFocusListener
                      arrow
                      title='Do not sell this asset!'
                      PopperProps={{ sx: tooltipStyle }}
                    >
                      <FormControlLabel
                        label='Never Sell'
                        value='neverSell'
                        id='neverSell'
                        control={
                          <input
                            type='checkbox'
                            name='neverSell'
                            checked={checkedState.includes('neverSell')}
                            onChange={e => handleChange('neverSell')}
                          />
                        }
                        sx={{ '& .MuiFormControlLabel-label': { paddingLeft: '0.5rem' } }}
                      />
                    </Tooltip>
                  </FormGroup>
                </FormControl>
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                onSave(updatedPreferences, ticker);
                handleClose();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AssetPreference;
