import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMarketOpen, isDomestic } from '../lib/utils';
import { COUNTRY, tooltipStyle, TICKCOLOR_UP, TICKCOLOR_DOWN } from '../lib/constants';
import Spinner from '../components/Spinner';
import PremiumLogic from '../components/dashboard/PremiumLogic';
import BuySignalList from '../components/dashboard/BuySignalList';
import {
  Box,
  Container,
  Stack,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import InsightsIcon from '@mui/icons-material/Insights';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MarketStatus from '../components/dashboard/MarketStatus';

const Dashboard = () => {
  const navigate = useNavigate();
  const { token, credentials, preferences, isLoading, isError, message } = useSelector(state => state.auth);
  const premiumLogic = preferences?.premiumLogic || null;
  const isMobile = useMediaQuery('(max-width: 600px)');

  const [tradingCountry, setTradingCountry] = useState(isMarketOpen(COUNTRY.USA) ? COUNTRY.USA : COUNTRY.KOREA);
  const tabLists = useMemo(() => {
    if (isDomestic(tradingCountry)) {
      return [
        { id: 0, label: premiumLogic, value: premiumLogic + 'UP', trend: 'UP' },
        { id: 1, label: premiumLogic, value: premiumLogic + 'DOWN', trend: 'DOWN' },
        { id: 2, label: 'Market Cap', value: 'marketcap', trend: 'UP' }
      ];
    } else {
      return [
        { id: 0, label: premiumLogic + ' USA', value: premiumLogic + 'USAUP', trend: 'UP' },
        { id: 1, label: premiumLogic + ' USA', value: premiumLogic + 'USADOWN', trend: 'DOWN' },
        { id: 2, label: 'MarketCap USA', value: 'marketcapUSA', trend: 'UP' }
      ];
    }
  }, [tradingCountry, premiumLogic]);

  const [tabValue, setTabValue] = useState(tabLists[0].value);

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }

    if (isError) {
      console.log(message);
    }
  }, [token, credentials, isError, message, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setTabValue(tabLists[0].value);
    }, 0);
  }, [tradingCountry, tabLists]);

  if (isLoading) {
    return <Spinner />;
  }

  const handleChange = (e, value) => setTabValue(value);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={isMobile ? 4 : 6}
        fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
      >
        <InsightsIcon fontSize={isMobile ? 'medium' : 'large'} /> Notable Stocks
      </Typography>

      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '800px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', typography: 'body1' }}
      >
        <Stack direction='row' justifyContent='flex-end' alignItems='center'>
          <Tooltip disableFocusListener arrow title='Trading Country' PopperProps={{ sx: tooltipStyle }}>
            <ToggleButtonGroup
              size='small'
              color='primary'
              value={tradingCountry}
              exclusive
              onChange={(e, country) => {
                if (country !== null) setTradingCountry(country);
              }}
              aria-label='Trading Country'
              sx={{ width: '150px' }}
            >
              <ToggleButton value={COUNTRY.KOREA}>Korea</ToggleButton>
              <ToggleButton value={COUNTRY.USA}>USA</ToggleButton>
            </ToggleButtonGroup>
          </Tooltip>
        </Stack>

        <Stack my={2} alignItems='flex-end'>
          <MarketStatus country={tradingCountry} />
        </Stack>

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label='Recommended Picks List Tabs'
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile={isMobile ? true : false}
            >
              {tabLists.map(list => {
                return (
                  <Tab
                    key={list.value}
                    icon={
                      list.trend === 'UP' ? (
                        <ArrowUpwardIcon fontSize='small' style={{ color: TICKCOLOR_UP }} />
                      ) : list.trend === 'DOWN' ? (
                        <ArrowDownwardIcon fontSize='small' style={{ color: TICKCOLOR_DOWN }} />
                      ) : null
                    }
                    iconPosition='end'
                    label={list.label}
                    value={list.value}
                  />
                );
              })}
            </TabList>
          </Box>
          {tabLists.map(list => {
            let panel = null;

            switch (list.value) {
              case 'marketcap':
                panel = (
                  <TabPanel key={list.value} value={list.value}>
                    <BuySignalList country={COUNTRY.KOREA} />
                  </TabPanel>
                );
                break;
              case 'marketcapUSA':
                panel = (
                  <TabPanel key={list.value} value={list.value}>
                    <BuySignalList country={COUNTRY.USA} />
                  </TabPanel>
                );
                break;
              default:
                list.trend === 'UP'
                  ? (panel = (
                      <TabPanel key={list.value} value={list.value}>
                        <PremiumLogic
                          premiumLogic={isDomestic(tradingCountry) ? premiumLogic : premiumLogic + 'US'}
                          trendType={'UP'}
                          country={tradingCountry.toUpperCase()}
                        />
                      </TabPanel>
                    ))
                  : (panel = (
                      <TabPanel key={list.value} value={list.value}>
                        <PremiumLogic
                          premiumLogic={isDomestic(tradingCountry) ? premiumLogic : premiumLogic + 'US'}
                          trendType={'DOWN'}
                          country={tradingCountry.toUpperCase()}
                        />
                      </TabPanel>
                    ));
                break;
            }

            return panel;
          })}
        </TabContext>
      </Box>
    </Container>
  );
};

export default Dashboard;
