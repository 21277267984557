export const COUNTRY = {
  KOREA: 'KOREA',
  USA: 'USA'
};

export const ENV = {
  SANDBOX: 'SANDBOX',
  PRODUCTION: 'PRODUCTION'
};

export const ROLE = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN'
};

export const TICKCOLOR_UP = '#f44336';
export const TICKCOLOR_DOWN = '#66bb6a';
export const TICKCOLOR_NEUTRAL = '#ffffff';

export const defaultPreferences = {
  profitRatio: '0',
  lossRatio: '0',
  takeProfit: '0',
  stopLoss: '0',
  buyPeriod: 'once',
  sellPeriod: 'once',
  buyPrice: 'market',
  sellPrice: 'market',
  buyQuantity: 'default',
  sellQuantity: 'default',
  autoTrade: 'off',
  premiumLogic: 'socrates',
  myFund: 'PRIMARY',
  tradingPriority: 'profitLoss',
  assetCount: '10',
  tradeBudgetRatio: '50',
  tradingCountry: 'KOREA',
  emptyOutPortfolio: 'no',
  tradingMode: ENV.SANDBOX
};

export const defaultFunds = [
  {
    name: 'PRIMARY',
    assets: []
  },
  {
    name: 'SECONDARY',
    assets: []
  },
  {
    name: 'INDEX',
    assets: []
  },
  {
    name: 'WATCH LIST',
    assets: []
  }
];

export const tooltipStyle = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(0,0,0)',
    color: 'white',
    fontSize: '14px'
  },
  '& .MuiTooltip-arrow': {
    color: 'rgba(0,0,0)'
  }
};
