import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { isDomestic } from '../lib/utils';

export const useDate = country => {
  const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 5000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  // const dateUTC = moment.tz(today, 'Europe/London');
  const dateKR = moment.tz(today, 'Asia/Seoul');
  const dateUSA = moment.tz(today, 'America/New_York');

  return isDomestic(country) ? dateKR : dateUSA;
};
